<template>
  <iframe
    src="https://vanderbilt.app.box.com/f/e6e7c55970a74933be339805c74296b7"
    sandbox="allow-scripts
    allow-same-origin"
    height="100%"
    width="100%"
  >
  </iframe>
</template>
<script>
export default {
  name: "UploadDocs",
};
</script>
